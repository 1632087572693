<template>
  <deviceBorrowItem
      :header-titel-kye="headerTitelKye"
      :show-start="viewType=='repair'?false:true"
      :class="{'backGround':showBackGround}"
      class="deviceFailure"
      loaddingClass="deviceFailure"
      :rowKeyList="rowKeyList"
      :keyList="keyList"
      :queryType="5"
  ></deviceBorrowItem>
</template>

<script>
import deviceBorrowItem from '@/components/deviceBorrowItem'

export default {
  name: 'mindReservation',
  components: { deviceBorrowItem },
  data() {
    return {
      headerTitelKye:'name',
      keyList: [
        {
          key: 'orderId',
          name: '预约单号',
          space: '21px',
          float: 'right',
          color: '#3E73FB'
        },
        {
          key: 'orderTime',
          name: '借用时间',
          space: '21px',
          float: 'right',
          color: '#3E73FB'
        },
        {
          key: "depreciationMethod",
          name: "折旧方式",
          space: "21px",
          float: "right",
        },
        {
          key: "yearLimit",
          name: "折 旧",
          space: "21px",
          float: "right",
        },
      ],
      rowKeyList: []
    }
  },
  beforeDestroy() {
  },
  created() {
    this.createStyle()

  },
  mounted() {
  },
  computed: {
    showBackGround() {
      let query = this.$route.query
      if (this.$valueIsExist(query, 'showBackGround')) {
        return true
      }
      return false
    },
    viewType() {
      let query = this.$route.query
      if (this.$valueIsExist(query, 'viewType')) {
        return query['viewType']
      }
      return ''
    }
  },
  methods: {
    createStyle: function() {
      if (this.viewType == '') {
        this.keyList = [
          {
            key: 'orderId',
            name: '预约单号',
            space: '21px',
            float: 'right',
            color: '#3E73FB'
          },
          {
            key: 'orderTime',
            name: '借用时间',
            space: '21px',
            float: 'right',
            color: '#3E73FB'
          }
        ]
        this.rowKeyList = [
          { key: 'brandName', name: '品 牌', space: '21px', float: 'right' },
          { key: 'modelName', name: '型 号', space: '21px', float: 'right' },
          { key: 'code', name: this.$VUEAPPDEVICECODE(), space: '21px', float: 'right' },
          { key: 'label', name: '卡 片 号', space: '3px', float: 'right' },
          {
            key: 'categoryName',
            name: '设备分类',
            space: '21px',
            float: 'right'
          },
          {
            key: "depreciationMethod",
            name: "折旧方式",
            space: "21px",
            float: "right",
          },
          {
            key: "yearLimit",
            name: "折 旧",
            space: "21px",
            float: "right",
          },
        ]
        return
      }
      this.headerTitelKye = 'deviceName';
      this.rowKeyList = [
        { key: 'deviceCode', name: this.$VUEAPPDEVICECODE(), space: '21px', float: 'right' },
        { key: 'deviceLabel', name: '卡 片 号', space: '3px', float: 'right' },
        {
          key: 'categoryName',
          name: '资产分类',
          space: '21px',
          float: 'right'
        },
        {
          key: 'detail',
          name: '资产故障',
          space: '21px',
          float: 'right'
        },
        {
          key: 'address',
          name: '资产位置',
          space: '21px',
          float: 'right'
        },
        {
          key: "depreciationMethod",
          name: "折旧方式",
          space: "21px",
          float: "right",
        },
        {
          key: "yearLimit",
          name: "折 旧",
          space: "21px",
          float: "right",
        },
      ]
      this.keyList = [
        {
          key: 'serialNumber',
          name: '预约单号',
          space: '21px',
          float: 'right',
          color: '#3E73FB',
          width: '100%'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.mindReservation {
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  overflow: auto;

  .list {
    height: 100%;
  }

  .emptyView {
    height: 100%;
  }

  .loadMore {
    text-align: center;
    color: $color4;
    font-size: 12px;
    padding: 12px;
  }
}

.backGround {
  background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
}

</style>
